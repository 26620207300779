import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RequestService } from './services/request/request.service';
import { CryptoService } from './services/crypto/crypto.service';
import { StorageService } from './services/storage/storage.service';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { BrowserService } from './services/browser/browser.service';
import { BackWardsService } from './services/backwards/backwards.service';
import { OnlyNumberDirective } from './directives/OnlyNumber/only-numbers.directive';
import { MaskPhoneDirective } from './directives/MaskPhone/mask-phone.directive';
import { OnlyLetterDirective } from './directives/OnlyLetters/only-letters.directive';
import { AnalyticsService } from './services/analytics/analytics.service';
import { ErrorService } from './services/errors/error.service';
import { EmptyStateService } from './services/emptyState/emptyStateService';
import { CognitoService } from './services/cognito/cognito.service';
import { OfficeService } from './services/office/office.service';
import { SellerService } from './services/seller/seller.service';
import { DeviceService } from './services/devices/device.service';
import { AlliesService } from './services/allies/allies.service';
import { MultipleSendService } from './services/multipleSend/multipleSend.service';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';

@NgModule({
  declarations: [
    HeaderComponent,
    OnlyNumberDirective,
    MaskPhoneDirective,
    OnlyLetterDirective,
    MultiSelectDropdownComponent,
  ],
  imports: [CommonModule, FormsModule, HttpClientModule],
  providers: [
    BackWardsService,
    RequestService,
    StorageService,
    CryptoService,
    BrowserService,
    AnalyticsService,
    ErrorService,
    EmptyStateService,
    CognitoService,
    OfficeService,
    SellerService,
    DeviceService,
    AlliesService,
    MultipleSendService,
  ],
  exports: [
    CommonModule,
    FormsModule,
    HeaderComponent,
    OnlyNumberDirective,
    MaskPhoneDirective,
    OnlyLetterDirective,
    MultiSelectDropdownComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UtilsUiModule {}
