export enum TypeDevice {
  SMARTPHONE = 'SMARTPHONE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export enum Breakpoints {
  MAX_WIDTH_CELLPHONE = 437,
  MAX_WIDTH_TABLET = 1024,
}
