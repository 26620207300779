import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { validateControlsACVX } from '../MaskPhone/mask-phone.directive';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[bdbOnlyLetter]',
})
export class OnlyLetterDirective {
  regexStr = '^[a-zA-Z]*$';

  constructor(private readonly el: ElementRef) {}

  // tslint:disable-next-line:no-input-rename
  @Input('bdbOnlyLetter') bdbOnlyLetter: boolean;

  validateControls(e): boolean {
    return (
      [46, 8, 9, 27, 13, 32, 110].indexOf(e.keyCode) !== -1 ||
      validateControlsACVX(e) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    );
  }

  @HostListener('keydown', ['$event']) onKeyDown(event): any {
    const e = event as KeyboardEvent;
    const regEx2 = new RegExp(this.regexStr);
    if (regEx2.test(e.key)) {
      return;
    } else {
      if (this.validateControls(e)) {
        // let it happen, don't do anything
        return;
      } else {
        e.preventDefault();
      }
    }
  }
}
