import { Injectable } from '@angular/core';
import { ErrorType, ERROR_STATUS, ERROR_TYPES } from '../../models/errorTypes';
import { Router } from '@angular/router';
import { BackWardsService } from '../backwards/backwards.service';
import { IconSelect } from '../../models/modalOptions';

@Injectable()
export class ErrorService {
  private readonly ERROR_PAGE = 'errorPage';
  private readonly MODAL = 'modal';

  constructor(
    private readonly backwardsService: BackWardsService,
    private readonly router: Router
  ) {}

  public async openScreenError(errorCode: string): Promise<string> {
    this.backwardsService.loadingChange(false);
    const errorType = this.filterErrorByCode(errorCode);

    if (this.isErrorTypeUndefined(errorType)) {
      console.error('Error undefined');
      return 'undefined error';
    }

    if (errorType.typeScreen === this.ERROR_PAGE) {
      await this.router.navigate(['error/'.concat(errorType.route)]);
      return this.ERROR_PAGE;
    } else {
      this.backwardsService.openModal({
        icon: IconSelect.ERROR,
        title: errorType.title,
        text: errorType.description,
        buttons: [
          {
            id: 'Cerrar',
            value: errorType.labelBtn,
          },
        ],
      });
      return this.MODAL;
    }
  }

  public isErrorTypeUndefined(errorType: ErrorType | undefined): boolean {
    if (!errorType) {
      return true;
    }
    return false;
  }

  public filterErrorbyHttp(httpErrorCode: number): string {
    if (ERROR_STATUS.SANDBOX.includes(httpErrorCode)) {
      return '0-001SHL';
    } else if (ERROR_STATUS.RECAPTCHA.includes(httpErrorCode)) {
      return '1-000SHL';
    }
    return '0-000SHL';
  }

  private filterErrorByCode(errorCode: string): ErrorType {
    return ERROR_TYPES.filter((error) => error.code === errorCode)[0];
  }
}
