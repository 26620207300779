import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '../../../utils-ui/models/customer';
import { AnalyticsService } from '../../../utils-ui/services/analytics/analytics.service';
import { BackWardsService } from '../../../utils-ui/services/backwards/backwards.service';
import { LinksService } from '../../services/links/links.service';
import { IModalButton, IconSelect } from '../../../utils-ui/models/modalOptions';
import { ICustomerInfo } from '../../model/CustomerInfo';
import { DynamicPipe } from '../../../utils-ui/pipes/dynamic.pipe';
import { LinkSessionService } from '../../../utils-ui/services/session/linkSession.service';
import { StorageService } from '../../../utils-ui/services/storage/storage.service';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.scss'],
})
export class ListClientsComponent {
  constructor(
    private readonly router: Router,
    private readonly linksService: LinksService,
    private readonly backwardsService: BackWardsService,
    private readonly analiticsService: AnalyticsService,
    private readonly linkSessionService: LinkSessionService,
    private readonly storageService: StorageService
  ) {}

  public static isArrayEmpty = true;

  @Input() id = '';
  @Input() channel: string;
  @Input() user: any;
  @Input() productXname: string;
  @Output() buttonStatusChange = new EventEmitter<any>();
  customers: Customer[] = [];
  buttonStatus = true;
  DynamicPipe = DynamicPipe;

  addClient(customer: Customer): void {
    this.customers.push(customer);
  }

  deleteClient(id: string): void {
    this.backwardsService
      .openModal({
        icon: IconSelect.WARNING,
        title: '¿Estas seguro de eliminar al cliente?',
        text: 'Una vez elimines su información, tendras que ingresarla de nuevo para enviarle un link de venta',
        buttons: [
          {
            id: 'primaryButton',
            value: 'Eliminar Cliente',
          },
          {
            id: 'secondaryButton',
            value: 'Cancelar',
          },
        ],
      })
      .then((event: IModalButton) => {
        if (event.id === 'primaryButton') {
          this.customers.splice(
            this.customers.findIndex((customer) => customer.identification === id),
            1
          );
          this.validateButton();
        }
      });
  }

  private setAllyInfo(): any {
    const allyInfo = this.storageService.getFromSession('alliedInfo');
    const allyParams = {
      allyCode: allyInfo?.ally_code ?? this.user.allyCode,
      allyName: allyInfo?.ally_name ?? this.user.allyName,
      allyPointSale: allyInfo?.sale_point_code ?? this.user.allyPointSale,
      pointSaleName: allyInfo?.sale_point_mame ?? this.user.allyPointSaleName,
      allyProduct: this.user.allyProduct,
      token: allyInfo?.token ?? this.user.token,
      branchCode: allyInfo?.sale_point_code ?? this.user.allyPointSale,
      allyId: allyInfo?.ally_id,
      advisorChiefIdentityNumber:
        this.storageService.getFromSession('advisorChiefIdentityNumber') ??
        this.user.advisorChiefIdentityNumber,
      advisorChiefIdentityType:
        this.storageService.getFromSession('advisorChiefIdentityType') ??
        this.user.advisorChiefIdentityType,
    };
    if (!allyParams.branchCode) {
      delete allyParams.branchCode;
    }
    return allyParams;
  }

  async generateLink(customerOpen: Customer): Promise<void> {
    this.backwardsService.loadingChange(true);

    this.analiticsService.trackPage({
      screenPath: 'envio-links',
      screenName: 'Enviando Links',
      username: this.user.username,
      identificationNumber: this.user.identificationNumber,
    });

    const customerToSendLinks: ICustomerInfo[] = this.customers.map((customer) => ({
      identificationType: customer.identificationType || 'CC',
      identificationNumber: customer.identification.toString(),
      firstname: encodeURI(customer.firstName),
      lastname: encodeURI(customer.lastName),
      cellphone: customer.phone ? customer.phone.toString() : '',
      email: customer.email || '',
      amount: customer.amount || 0,
      isClient: customer.isClient,
    }));

    if (customerOpen.identification) {
      customerToSendLinks.push({
        identificationType: customerOpen.identificationType || 'CC',
        identificationNumber: customerOpen.identification.toString(),
        firstname: encodeURI(customerOpen.firstName),
        lastname: encodeURI(customerOpen.lastName),
        cellphone: customerOpen.phone ? customerOpen.phone.toString() : '',
        email: customerOpen.email || '',
        amount: customerOpen.amount || 0,
        isClient: customerOpen.isClient,
      });
    }

    try {
      await this.linksService.generateLink(
        customerToSendLinks,
        this.user.officeId,
        {
          productId: this.id.toString(),
          rqUuid: this.user.rqUuid,
          channel: this.user.channel,
          advisorUsername: this.user.username,
          IpAddr: this.user.ip,
          product_Xname: this.productXname,
        },
        {
          ...this.setAllyInfo(),
        }
      );
      this.backwardsService.loadingChange(false);
      this.analiticsService.trackPage({
        screenPath: 'link-Enviado',
        screenName: 'Link Enviado',
        username: this.user.username,
        identificationNumber: this.user.identificationNumber,
      });

      this.linkSessionService.setSendingLinks(customerToSendLinks);
      this.linkSessionService.setProductSelectedSession(Number(this.id));
      ListClientsComponent.isArrayEmpty = true;
      await this.router.navigate(['dashboard/clients-list']);
    } catch (error) {
      this.backwardsService.loadingChange(false);
      throw error;
    }
  }

  changeButtonStatus(status: boolean): void {
    this.buttonStatusChange.emit(status);
  }

  validateButton(): void {
    if (this.customers.length > 0) {
      ListClientsComponent.isArrayEmpty = false;
      this.buttonStatus = false;
    } else {
      ListClientsComponent.isArrayEmpty = true;
      this.buttonStatus = true;
    }
    this.changeButtonStatus(this.buttonStatus);
  }
}
