import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[bdbMaskPhone]',
})
export class MaskPhoneDirective {
  regexStr = '^[0-9]*$';

  constructor(private readonly el: ElementRef) {}

  // tslint:disable-next-line:no-input-rename
  @Input('bdbMaskPhone') bdbMaskPhone: boolean;

  validateControls(e): boolean {
    return (
      [46, 8, 9, 27, 13, 106].indexOf(e.keyCode) !== -1 ||
      validateControlsACVX(e) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    );
  }

  @HostListener('keydown', ['$event']) onKeyDown(event): any {
    const e = event as KeyboardEvent;
    const regEx2 = new RegExp(this.regexStr);
    if (regEx2.test(e.key)) {
      return;
    } else {
      if (this.validateControls(e)) {
        // let it happen, don't do anything
        return;
      } else {
        e.preventDefault();
      }
    }
  }
}

export function validateControlsACVX(e): any {
  return (
    (e.keyCode === 65 && e.ctrlKey) ||
    // Allow: Ctrl+C
    (e.keyCode === 67 && e.ctrlKey) ||
    // Allow: Ctrl+V
    (e.keyCode === 86 && e.ctrlKey) ||
    // Allow: Ctrl+X
    (e.keyCode === 88 && e.ctrlKey) ||
    // Allow: Command+C for MacOs
    (e.keyCode === 67 && e.metaKey) ||
    // Allow: Command+V for MacOs
    (e.keyCode === 86 && e.metaKey) ||
    // Allow: Command+X for MacOs
    (e.keyCode === 88 && e.metaKey)
  );
}
