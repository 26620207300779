import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[bdbOnlyNumber]',
})
export class OnlyNumberDirective {
  regexStr = '^[0-9]*$';

  constructor(private readonly el: ElementRef) {}

  @Input() bdbOnlyNumber: boolean;

  validateControlsACVX(e): boolean {
    const validKey = [65, 67, 86, 88].indexOf(e.keyCode);
    return (validKey && e.ctrlKey) || (validKey && e.metaKey);
  }

  validateControls(e): boolean {
    return (
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      this.validateControlsACVX(e) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    );
  }

  @HostListener('keydown', ['$event']) onKeyDown(event): void {
    const e = event as KeyboardEvent;
    const regEx2 = new RegExp(this.regexStr);
    if (regEx2.test(e.key)) {
      return;
    } else {
      if (this.validateControls(e)) {
        // let it happen, don't do anything
        return;
      } else {
        e.preventDefault();
      }
    }
  }
}
