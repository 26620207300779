import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-invisible-recaptcha',
  templateUrl: './invisible-recaptcha.component.html',
})
export class InvisibleRecaptchaComponent {
  readonly urlRecaptcha = environment.urlRecapctha + environment.RECAPTCHA_WK;

  @Output() recaptchaSuccess = new EventEmitter<string>();
  @ViewChild(RecaptchaComponent) captchaRef: RecaptchaComponent;

  handleSuccess(token: string): void {
    this.recaptchaSuccess.emit(token);
  }

  executeRecaptchaV2(): void {
    this.captchaRef.execute();
  }

  handleError(): void {
    console.error('Recaptcha error');
  }
}
