import { Injectable } from '@angular/core';
import { ICustomerInfo } from '../../../dashboard/model/CustomerInfo';
import { ProductFromService } from '../../models/product';

@Injectable({
  providedIn: 'root',
})
export class LinkSessionService {
  customerToSendLinks: ICustomerInfo[] = [];
  productsSession: ProductFromService[] = [];
  productSelectedSession: number;

  setSendingLinks(sendLinks: ICustomerInfo[]): void {
    this.customerToSendLinks = sendLinks;
  }

  setProductsSession(products: ProductFromService[]): void {
    this.productsSession = products;
  }

  setProductSelectedSession(productSelected: number): void {
    this.productSelectedSession = productSelected;
  }
}
