import { Injectable } from '@angular/core';
import { IUserStorage } from '../../models/userStorage';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  dataLayer: Array<any>;
  userStorage: IUserStorage;

  constructor(private readonly storageService: StorageService) {
    this.dataLayer = window['dataLayer'] === undefined ? [] : window['dataLayer'];
  }

  public trackPage(questionParams?): void {
    this.dataLayer.push({
      screenPath: '/' + questionParams.screenPath,
      screenName: questionParams.screenName,
      canal_cliente: this.storageService.getUser()?.channelHomologateCode,
      userId: window.btoa(questionParams.identificationNumber),
      Producto: questionParams.Producto,
    });
    this.dataLayer.push({ event: 'appScreenView' });
  }

  public cleanDataLayer(): void {
    this.dataLayer.length = 0;
  }
}
