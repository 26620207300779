import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestParams } from '../../models/requestParams';
import { environment } from '../../../../environments/environment';
import { MngrLocal } from '../../models/endpoints';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { IUserStorage } from '../../models/userStorage';
import { lastValueFrom, retry } from 'rxjs';
import { ambience } from '../../../../assets/data/constants';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
    private readonly router: Router
  ) {}

  private async handleMessage(response: HttpResponse<any>, params: RequestParams): Promise<any> {
    return Promise.resolve(response).then((res) => this.validateContent(res, params));
  }

  private validateContent(response: HttpResponse<any>, pParams: RequestParams): any {
    let body = response.body;
    const authToken = response.headers.get('x-auth-token');

    if (pParams.responseType === 'text') {
      body = this.storageService.cryptoService.getEncryptionResponse(response.body, authToken);
    }

    this.setIpAddress(response.headers);
    return body;
  }

  private setIpAddress(headers: HttpHeaders): void {
    if (!this.storageService.getUser()) {
      const ipAddress = headers.get('X-IPAddr');
      this.storageService.saveUser({ ipAddress });
    }
  }

  /**
   * GET request function
   * @param (string) pUri resource of the request
   * @param (Object) [pParams={}] Information for the request
   * @param (Object) [pParams.queryParams] Request query params
   * @param (HttpHeaders) [pParams.headers] Headers to override
   * @param (string) [pParams.responseType=json] type to override into response
   */
  async get(pUri: string, pParams?: RequestParams, retries = 0, delays = 0): Promise<any> {
    const totalParams = this.defineParams(pParams, pUri);
    totalParams.options = totalParams.options.set('x-strcode', '');

    const authToken = (this.storageService.getUser() as IUserStorage)?.rqUuid;
    const { encryptedBody, options } = this.storageService.cryptoService.setEncryptionRequest(
      pParams.queryParams,
      authToken,
      totalParams.options,
      pParams.headers
    );
    pParams.queryParams = encryptedBody;
    totalParams.pUri = `${totalParams.pUri}?cypher=${encryptedBody}`;
    totalParams.options = options;

    return lastValueFrom(
      this.http
        .get(totalParams.pUri, {
          headers: totalParams.options,
          params: undefined,
          responseType: pParams.responseType,
          observe: 'response',
        })
        .pipe(retry({ count: retries, delay: delays }))
    )
      .then((res: HttpResponse<any>) => this.handleMessage(res, pParams))
      .catch((err) => this.handleError(err));
  }

  /**
   * POST request function
   * @param (string) pUri resource of the request
   * @param (Object) [pParams={}] Information for the request
   * @param (Object) [pParams.body={}] Request body
   * @param (Object) [pParams.queryParams] Request query params
   * @param (HttpHeaders)[pParams.headers] Headers to override
   * @param (string) [pParams.responseType=json] type to override into response
   */
  async post(pUri: string, pParams?: RequestParams, retries = 0, delays = 0): Promise<any> {
    const totalParams = this.defineParams(pParams, pUri);
    let body = totalParams.pParams.body;
    if (!pParams.externalEndpoint) {
      const authToken = (this.storageService.getUser() as IUserStorage)?.rqUuid;
      const { encryptedBody, options } = this.storageService.cryptoService.setEncryptionRequest(
        body,
        authToken,
        totalParams.options,
        pParams.headers
      );
      body = encryptedBody;
      totalParams.options = options;
    }

    pParams.responseType = 'text';
    return lastValueFrom(
      this.http
        .post(totalParams.pUri, body, {
          headers: totalParams.options,
          params: totalParams.pParams.queryParams,
          responseType: totalParams.pParams.responseType,
          observe: 'response',
        })
        .pipe(retry({ count: retries, delay: delays }))
    )
      .then((res: HttpResponse<any>) => this.handleMessage(res, pParams))
      .catch((err) => this.handleError(err));
  }

  /**
   * PUT request function
   * @param (string) pUri resource of the request
   * @param (Object) [pParams={}] Information for the request
   * @param (Object) [pParams.body={}] Request body
   * @param (Object) [pParams.queryParams] Request query params
   * @param (HttpHeaders) [pParams.headers] Headers to override
   * @param (string) [pParams.responseType=json] type to override into response
   */
  async put(pUri: string, pParams?: RequestParams, retries = 0, delays = 0): Promise<any> {
    const totalParams = this.defineParams(pParams, pUri);
    let body = totalParams.pParams.body;

    if (!pParams.externalEndpoint) {
      const authToken = (this.storageService.getUser() as IUserStorage)?.rqUuid;
      const { encryptedBody, options } = this.storageService.cryptoService.setEncryptionRequest(
        body,
        authToken,
        totalParams.options,
        pParams.headers
      );
      body = encryptedBody;
      totalParams.options = options;
    }

    return lastValueFrom(
      this.http
        .put(totalParams.pUri, body, {
          headers: totalParams.options,
          params: totalParams.pParams.queryParams,
          responseType: totalParams.pParams.responseType,
          observe: 'response',
        })
        .pipe(retry({ count: retries, delay: delays }))
    )
      .then((res: HttpResponse<any>) => this.handleMessage(res, pParams))
      .catch((err) => this.handleError(err));
  }

  /**
   * DELETE request function
   * @param (string) pUri resource of the request
   * @param (Object) [pParams={}] Information for the request
   * @param (Object) [pParams.queryParams] Request query params
   * @param (HttpHeaders) [pParams.headers] Headers to override
   * @param (string) [pParams.responseType=json] type to override into response
   */
  async delete(pUri: string, pParams?: RequestParams, retries = 0, delays = 0): Promise<any> {
    const totalParams = this.defineParams(pParams, pUri);
    pParams = pParams || {};

    const authToken = (this.storageService.getUser() as IUserStorage)?.rqUuid;
    const { encryptedBody, options } = this.storageService.cryptoService.setEncryptionRequest(
      pParams.queryParams,
      authToken,
      totalParams.options,
      pParams.headers
    );

    totalParams.pUri = `${totalParams.pUri}?cypher=${encryptedBody}`;
    totalParams.options = options;

    return lastValueFrom(
      this.http
        .delete(totalParams.pUri, {
          headers: totalParams.options,
          params: pParams.queryParams,
          responseType: pParams.responseType,
          observe: 'response',
        })
        .pipe(retry({ count: retries, delay: delays }))
    )
      .then((res: HttpResponse<any>) => this.handleMessage(res, pParams))
      .catch((err) => this.handleError(err));
  }

  handleError(err: any): Promise<any> {
    if (err?.status === 401) {
      this.router.navigate([ambience[environment.IS_FRONT_ALLY]]);
    }

    return Promise.reject(err);
  }

  private defineParams(pParams: RequestParams, pUri: string): any {
    let options = new HttpHeaders();
    const userStorage = this.storageService.getUser() as IUserStorage;
    pParams = pParams || {};
    pParams.body = pParams.body || {};
    pParams.responseType = pParams.responseType || 'json';
    pUri = pParams.externalEndpoint ? pUri : environment.apiSbx.concat(pUri);
    pUri = this.testLocal(pUri);

    if (pParams.externalEndpoint) {
      options = this.assingHeaders(options, pParams.headers);
    }

    options = options.set('Content-Type', 'application/json');

    if (pParams.body.constructor.name === 'FormData') {
      options = options.delete('Content-Type', '');
    }

    if (!pParams.externalEndpoint && !pParams.avoidToken) {
      options = options.set('X-RqUID', userStorage.rqUuid);
    }

    return { pUri, pParams, options };
  }

  private assingHeaders(head: HttpHeaders, pHeaders: any): HttpHeaders {
    const headerNames: string[] = Object.keys(pHeaders);
    headerNames.forEach((name) => {
      head = head.set(name, pHeaders[name]);
    });
    return head;
  }

  private testLocal(pUri: string): string {
    if (pUri.includes('localhost')) {
      const mngrPaths = Object.keys(MngrLocal);
      mngrPaths.forEach((path) => {
        pUri = pUri.replace(path, MngrLocal[path]);
      });
    }

    return pUri;
  }
}
