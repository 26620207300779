export enum Endpoints {
  CREATE_REQUEST = `/remote-client-contact-mngr-ecs/V1/Utilities/request`,
  PRODUCTS = `/remote-client-contact-mngr-ecs/V1/Utilities/product`,
  CUSTOMERS = `/remote-client-contact-mngr-ecs/V1/Utilities/customers`,
  CUSTOMERSSAFEINFO = `/remote-client-contact-mngr-ecs/V1/Utilities/customerssafe`,
  CUSTOMERSSAFEINFOPUT = `/remote-client-contact-mngr-ecs/V1/Utilities/customerssafeinfo`,
  DATA_TREATMENT = `/remote-client-contact-mngr-ecs/V1/Utilities/datatreatment`,
  ADVISORS = `/remote-client-contact-mngr-ecs/V1/Utilities/advisors`,
  GENERATE_LINK = `/remote-client-contact-mngr-ecs/V1/Utilities/links/generate`,
  RETRY_LINK = `/remote-client-contact-mngr-ecs/V1/Utilities/links/retry`,
  RESEND_LINK = `/customer-notification-mngr/V2/Utilities/customers/notification/retry`,
  ENABLE_WHATSAPP = `/customer-notification-mngr/V2/Utilities/Whatsapp/Quota`,
  GET_LINKS = `/remote-client-contact-mngr-ecs/V1/Utilities/advisor/links`,
  LINKS_BY_PRODUCT = `/remote-client-contact-mngr-ecs/V2/Utilities/advisor/links`,
  LINKS_BY_ADVISOR = `/remote-client-contact-mngr-ecs/V3/Utilities/advisor/links`,
  LINK_PRODUCTS_BY_ADVISOR = `/remote-client-contact-mngr-ecs/V2/Utilities/advisor/links/products`,
  LINKS_BY_CUSTOMER_ID = `/remote-client-contact-mngr-ecs/V1/Utilities/customers/links`,
  NOTIFY_CUSTOMERS = `/customer-notification-mngr/V1/Utilities/customers/notification`,
  LOGIN = `/session-mngr/V1/Utilities/login`,
  LOGOUT = `/session-mngr/V1/Utilities/sessions`,
  VALIDATE_SESSION = `/session-mngr/V1/Utilities/sessions/validate`,
  CHANNEL = `/remote-client-contact-mngr-ecs/V1/Utilities/channels`,
  SET_ACCES_TOKEN = `/session-mngr/V1/Utilities/signin`,
  VALIDATE_MACHINE = '/validate-machine/V1/Utilities/machine',
  MASTER_DATA = `/remote-client-contact-mngr-ecs/V1/Utilities/masterdata`,
  RECAPTCHA = `/session-mngr/V1/Utilities/recaptcha`,
  SELLER = `/remote-client-contact-mngr-ecs/V1/Utilities/sellers`,
  ALLIED = `/remote-client-contact-mngr-ecs/V1/Utilities/allied`,
  MULTIPLE_SEND = '/remote-client-contact-mngr-ecs/V1/Utilities/product',
}

export const MngrLocal = {
  '/remote-client-contact-mngr': ':8090',
  '/customer-notification-mngr': ':8091',
  '/session-mngr': ':8092',
} as const;
