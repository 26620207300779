import { Injectable } from '@angular/core';
import * as bowser from 'bowser';
import { RequestService } from '../request/request.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  private readonly browser: any;
  public device: any;

  constructor(private readonly requestService: RequestService) {
    this.browser = bowser.getParser(window.navigator.userAgent);
  }

  checkBrowser(): boolean {
    const isInstagramOrFacebook = this.isInstagramOrFacebook(window.navigator.userAgent);

    const isValidBrowser = this.browser.satisfies({
      chrome: '>65',
      safari: '>10',
      firefox: '>60',
    });

    return isValidBrowser || isInstagramOrFacebook;
  }

  getBrowserName(): string {
    return this.browser.getBrowserName();
  }

  getPlatform(): any {
    return this.browser.getPlatform();
  }

  private isInstagramOrFacebook(e): boolean {
    return e.indexOf('Instagram') > -1 || e.indexOf('FBAN') > -1 || e.indexOf('FBAV') > -1;
  }
}
