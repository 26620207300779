import { Injectable } from '@angular/core';
import { Endpoints } from '../../../utils-ui/models/endpoints';
import { RequestService } from '../../../utils-ui/services/request/request.service';
import { StorageService } from '../../../utils-ui/services/storage/storage.service';
import { IUserStorage } from '../../../utils-ui/models/userStorage';
import { ICustomerInfo } from '../../model/CustomerInfo';
import { ILink } from '../../model/ILink';
import { providers } from '../../model/ResendOptions';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  constructor(
    private readonly requestService: RequestService,
    private readonly storageService: StorageService
  ) {}

  generateLink(
    pCustomerList: ICustomerInfo[],
    advisorOfficeId: string,
    pParms: {
      productId: string;
      rqUuid: string;
      channel: string;
      advisorUsername: string;
      IpAddr: string;
      product_Xname: string;
    },
    allyInfo?: any
  ): Promise<void> {
    const userStorage = this.storageService.getUser() as IUserStorage;
    const body: any = {
      customers: pCustomerList,
      advisorOfficeId,
    };

    if (allyInfo.allyCode || allyInfo.token) {
      body.allyInfo = allyInfo;
    }

    return this.requestService.put(Endpoints.GENERATE_LINK, {
      headers: {
        'x-advisor-username': pParms.advisorUsername,
        'x-productId': pParms.productId,
        'x-channel': pParms.channel,
        'x-IpAddr': pParms.IpAddr,
        'x-productName': pParms.product_Xname,
        'office-code': userStorage.officeCode,
        'seller-code': userStorage.sellerCode,
      },
      responseType: 'text',
      body,
    });
  }

  async resendLink(resendData: ILink, provider: providers): Promise<void> {
    const userData = this.storageService.getUser();
    const headers = {
      'X-RqUID': userData.rqUuid,
      'x-provider': provider,
      'x-ipaddr': userData.ip,
    };
    const body = {
      advisor: {
        identificationNumber: userData.identificationNumber,
        identificationType: 'CC',
        userName: userData.username,
        officeCode: userData.officeCode,
        sellerCode: userData.sellerCode,
        allyCode: this.storageService.getFromSession('allyCode') ?? userData.allyCode,
        allyName: userData.allyName,
        allyPointSale: userData.allyPointSale,
        allyPointSaleName: userData.allyPointSaleName,
        allyProduct: userData.allyProduct,
        token: userData.token,
        branchCode: this.storageService.getFromSession('branchCode') ?? userData.branchCode,
        advisorChiefIdentityNumber:
          this.storageService.getFromSession('advisorChiefIdentityNumber') ??
          userData.advisorChiefIdentityNumber,
        advisorChiefIdentityType:
          this.storageService.getFromSession('advisorChiefIdentityType') ??
          userData.advisorChiefIdentityType,
      },
      channel: String(userData.channelId),
      linkCodeId: String(resendData.id),
      customerIdentificationNumber: resendData.identification_number,
    };
    return this.requestService.post(Endpoints.RESEND_LINK, {
      headers,
      body,
    });
  }

  async enableWhatsapp(codeIdLink: string): Promise<any> {
    const userData = this.storageService.getUser();
    const headers = {
      'X-RqUID': userData.rqUuid,
      'x-ipaddr': userData.ip,
      'X-linkCodeId': codeIdLink,
    };
    return this.requestService.get(Endpoints.ENABLE_WHATSAPP, {
      headers,
    });
  }
}
