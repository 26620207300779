import { Injectable } from '@angular/core';
import { Endpoints } from '../../models/endpoints';
import { RequestService } from '../request/request.service';
import { RawOfficesData } from '../../../dashboard/model/OfficesData';

@Injectable()
export class OfficeService {
  constructor(private readonly requestService: RequestService) {}

  public getOffices(idPart: string): Promise<RawOfficesData[]> {
    const masterDataUrl = `${Endpoints.MASTER_DATA}/${idPart}`;
    return this.requestService.get(masterDataUrl, {
      avoidToken: true,
      queryParams: {},
      responseType: 'text',
    });
  }
}
