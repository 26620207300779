import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dynamic' })
export class DynamicPipe implements PipeTransform {
  private static readonly LIMIT_CHARS_TABLE = 15;

  public static limitCharsUtil(
    value: string,
    limit: number = DynamicPipe.LIMIT_CHARS_TABLE
  ): string {
    const concat = value.length > limit ? '...' : '';
    return value.substring(0, limit).concat(concat);
  }

  transform(value: string, modifier: string): any {
    if (!modifier) {
      return value;
    }
    return this[modifier](value);
  }

  date(value: string): string {
    const dateFmt = new Intl.DateTimeFormat('es-CO', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return dateFmt.format(new Date(value));
  }

  limitCharsTable(value: string): string {
    return DynamicPipe.limitCharsUtil(value);
  }

  obfuscatePhoneNumber(phone: string): string {
    const cad = phone.split('');
    for (let i = 3; i < phone.length - 2; i++) {
      cad[i] = '*';
    }
    phone = cad.join('');
    return phone;
  }
}

export const DYNAMIC_PIPES = [DynamicPipe];
