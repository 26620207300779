export const commonEnvsDev = {
  production: 'qa',
  api: 'https://api-xshl.labdigbdbqaxshl.com',
  apiSbx: 'https://api-shortlinks-sbx.labdigbdbqaxshl.com',
  PV_KEY: '',
  PB_KEY: '',
  CYPHER_JS_KEY: '',
  tagManagerId: '',
  SBX_PARAMETERS_v2: {
    Parameters: {
      Time: 3,
      Body: 0,
      Iv: 9,
      Pk1: 2,
      Pk2: 6,
      Total: 12,
    },
    Expiration: {
      Time: 35,
      Unit: 'seconds',
    },
  },
  SBX_RQ_PBK:
    'MTY1LDYzLDI1MCw3OSwyMjAsNTgsMTU2LDIyMiwxNjAsMTAxLDIyNCwxMCwyMzUsMTAyLDEyMiw2OCwxNTksODcsODUsODcsMTgyLDE2OSw5OCwxMjksOTMsMTkxLDExNSwxOTIsMTI0LDExMiwyNTMsMjc=',
  SBX_RS_PBK:
    'NzksOTIsMjksMTY3LDQxLDcwLDEwNiw0MiwyMywxNzksMTAyLDg0LDIyMSwxNjUsMjM0LDI0OCwyMDEsNzgsMTk3LDE0MSwyMjcsMjM0LDQ0LDEwNywyMzQsNTEsMTM1LDI0LDMxLDI2LDExMCwyMDA=',
  encryptKey:
    'MTAzLDE4MCwxLDM2LDgxLDE5LDIwMSwzNCw2Miw3OCwxMDYsMjQxLDU3LDIzOCwxMjYsNDAsMTcyLDE4MCwxOTgsMTY4LDcsODcsNDAsMTM4LDYwLDY2LDE1MywxODIsMTI3LDM2LDE3NSw2OA==',
  decryptKey:
    'MTk1LDEwOCwxMzcsOTEsMjEsMTU2LDE3LDIxMSwxNTEsMTM1LDIyNSw2MSwxMDYsMTUsMjE0LDIxOCw1NywyMjgsNDcsODIsMjUsMTMxLDU5LDE1NiwxNjIsMTc2LDQwLDQyLDEwLDg1LDE0LDE3',
  urlRecapctha: 'https://www.google.com/recaptcha/api.js?render=',
  RECAPTCHA_SITE_KEY_V2: '6LdC6wEkAAAAAI35ADZA10dp4ZEnbrhYgSSOhV3o',
  RECAPTCHA_WK: '6LeZsJscAAAAAPNr5QDV_jW1zDR8zFnMZDrnEO_v',
  awsUserPoolsId: 'us-east-1_ldlahka7s',
  awsUserPoolsWebClientId: '1g3ouupef5030g7khbk64l5v9i',
  ALLY_CHANNEL_ID: '7',
  LINKS_LIMIT: 5,
  TOGGLE_SCRIPT_HOTJAR: true,
  ID_HOTJAR: '3910558',
  TOGGLE_RECAPTCHA_V2: true,
  TOGGLE_MODAL_CC: false,
  ENABLE_WHAPSAPP: true,
  TIME_TO_EXPIRE_LINK: 10,
  TOGGLE_CUSTOMER_BY_ID: true,
  traces: {
    enabled: false,
    host: 'https://observability.alb.labdigstgmonitoring.com',
    type: 'internal',
    metricsEndpoint: 'https://api-metrics.labdigstgmonitoring.com/graphql',
  },
  productWithAmountCol: ['CeroPay'],
  showEmptyState: false,
  redirectExternally: 'https://www.bancodebogota.com/personas',
};
