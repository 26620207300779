import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { UtilsUiModule } from './utils-ui/utils-ui.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CanActivateAuthGuard } from './utils-ui/services/session/canActivateAuthGuard.service';
import { RecaptchaCustomModule } from './recaptcha/recaptcha.module';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, UtilsUiModule, RecaptchaCustomModule],
  providers: [CanActivateAuthGuard],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
