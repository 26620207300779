import { Injectable } from '@angular/core';
import { Endpoints } from '../../models/endpoints';
import { RequestService } from '../request/request.service';

@Injectable()
export class MultipleSendService {
  constructor(private readonly requestService: RequestService) {}

  public async getMultipleSend(channelHomologateCode: string, productName: string): Promise<any> {
    const multipleSendUrl = `${Endpoints.MULTIPLE_SEND}/${productName}/multipleSend/${channelHomologateCode}`;
    try {
      return await this.requestService.get(multipleSendUrl, {
        avoidToken: true,
        responseType: 'text',
      });
    } catch (error) {
      throw new Error('Error trying to get multiple_send');
    }
  }
}
