import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BackWardsService } from '../backwards/backwards.service';

@Injectable()
export class EmptyStateService {
  constructor(
    private readonly backwardsService: BackWardsService,
    private readonly router: Router
  ) {}

  public async openScreenEmptyState() {
    this.backwardsService.loadingChange(false);
    await this.router.navigate(['emptyState']);
  }
}
