import { Component, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { BdbWebTracer } from '@npm-bbta/sdk-mfe-utils-opentelemetry';
import { NavigationStart, Router } from '@angular/router';
import { Components } from '@npm-bbta/bbog-dig-dt-webcomponents-lib/loader';
import { Subject } from 'rxjs';
import { HeaderComponent } from './utils-ui/components/header/header.component';
import { IModalButton, IModalOptions } from './utils-ui/models/modalOptions';
import { InstrumentationEnum } from '@npm-bbta/sdk-mfe-utils-opentelemetry/enums/InstrumentationEnum';
import { BackWardsService } from './utils-ui/services/backwards/backwards.service';
import { StorageService } from './utils-ui/services/storage/storage.service';
import { environment } from '../environments/environment';
import { EmptyStateService } from './utils-ui/services/emptyState/emptyStateService';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public static headerEvent: Subject<any> = new Subject<any>();
  private readonly showEmptyState = environment.showEmptyState;
  loading: boolean;
  title = 'bbog-ae-remote-client-contact-web-ui';
  modalOptions: any;
  private resolveModal: Subject<IModalButton>;
  component: any;

  @ViewChild('header') header: HeaderComponent;
  @ViewChild('modal') modal: ElementRef<Components.BdbMlModal>;

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly elementRef: ElementRef,
    private readonly ngZone: NgZone,
    private readonly emptyStateService: EmptyStateService
  ) {
    if (this.showEmptyState === true) {
      this.emptyStateService.openScreenEmptyState();
    } else {
      this.loading = false;
      this.onModalEvents();
      this.onLoadingChange();

      router.events.subscribe(() => {
        let navStorage = this.storageService.getNav();

        if (null === navStorage || document.location.href !== navStorage.actUrl) {
          navStorage = {
            prevUrl: navStorage ? navStorage.actUrl : undefined,
            actUrl: document.location.href,
          };
          this.storageService.saveNav(navStorage);
        }
      });

      if (environment.traces.enabled) {
        const bdbTrace = new BdbWebTracer({
          appName: `${this.title}-${environment.traces.type}-Front`,
          team: 'Shl',
          collectorHost: environment.traces.host,
          instruments: [InstrumentationEnum.XML_HTTP_REQUEST],
          processorType: 'Simple',
          spanTypes: ['OTLP'],
          propagateTraceHeaderCorsUrls: [],
          propagateHeaders: ['x-auth-token', 'x-rquid'],
          propagateStorage: true,
          prefixStorage: '@SHL',
          metrics: {
            enabled: environment.traces.enabled,
            endpoint: environment.traces.metricsEndpoint,
            sandboxConfig: {
              encryptKey: environment.encryptKey,
              decryptKey: environment.decryptKey,
              properties: {
                Parameters: {
                  Time: 5,
                  Body: 3,
                  Iv: 2,
                  Pk1: 1,
                  Pk2: 4,
                  Total: 6,
                },
                Expiration: {
                  Time: 5,
                  Unit: 'minutes',
                },
              },
            },
          },
        });
        bdbTrace.init();
        this.registerNavigationTraces(bdbTrace);
      }
    }
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.removeAttribute('ng-version');
  }

  onModalEvents(): void {
    BackWardsService.modalEvent.subscribe(
      (event: { clickEvent: Subject<IModalButton>; modalOptions: IModalOptions }) => {
        this.modal.nativeElement.optionsButtons = event.modalOptions.buttons;
        this.modal.nativeElement.icon = event.modalOptions.icon;
        this.modal.nativeElement.titleModal = event.modalOptions.title;
        this.modal.nativeElement.subTitle = event.modalOptions.text;
        this.resolveModal = event.clickEvent;
        try {
          this.modal.nativeElement.openAlert();
          const modaltemp = document.getElementsByClassName('modal')[0];
          const uniqueId = 'ae_links_modal_' + event.modalOptions.title.split(' ').join('_');
          modaltemp.id = uniqueId;
          const modaltempShadow = document.querySelector('.modal').shadowRoot;
          const buttons = modaltempShadow.children[0].getElementsByTagName('button');
          const values = event.modalOptions.buttons;
          for (let i = 0; i < buttons.length; i++) {
            buttons[i].setAttribute('id', `${uniqueId}_${values[i]['value']}`);
          }
        } catch (error) {}
      }
    );
  }

  modalClicked(pEvent: any): void {
    this.resolveModal.next(pEvent.detail);
  }

  onLoadingChange(): void {
    BackWardsService.loadingEvent.subscribe((event: boolean) => {
      this.loading = event;
    });
  }

  registerNavigationTraces(trace: BdbWebTracer): void {
    this.ngZone.runOutsideAngular(() => {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          trace.registerCustomTrace('Angular-Navigation', {
            'framework': 'Angular',
            'event.navigation': NavigationStart.name,
          });
        }
      });
    });
  }
}
