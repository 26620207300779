import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppComponent } from '../../../app.component';
import { IconSelect, IModalOptions } from '../../models/modalOptions';
import { ToastOptions } from '../../models/toastOptions';

@Injectable({
  providedIn: 'root',
})
export class BackWardsService {
  public static modalEvent = new Subject<any>();
  public static loadingEvent = new Subject<boolean>();
  public static toastEvent = new Subject<ToastOptions>();

  private readonly modalOptions: IModalOptions;
  public closeEnable: boolean;
  public backEnable: boolean;
  public pathBacktoBack: string;

  constructor() {
    this.modalOptions = {
      icon: IconSelect.SUCCESS,
      title: '',
      text: '',
      buttons: [
        {
          id: 'primaryButton',
          value: 'Ok',
        },
      ],
    };
  }

  public setUrl(closeEnable: boolean, backEnable: boolean, pathBacktoBack?: string): void {
    AppComponent.headerEvent.next({
      pathBacktoBack,
      backEnable,
      closeEnable,
    });
    this.closeEnable = closeEnable;
    this.backEnable = backEnable;
    this.pathBacktoBack = pathBacktoBack;
  }

  openModal(pOptions: IModalOptions): Promise<any> {
    return new Promise((resolve) => {
      const clickEvent = new Subject<Event>();
      this.modalOptions.icon = IconSelect[pOptions.icon];
      this.modalOptions.title = pOptions.title;
      this.modalOptions.text = pOptions.text;

      if (pOptions.buttons.length > 0) {
        this.modalOptions.buttons = pOptions.buttons;
      }

      clickEvent.subscribe((event) => {
        resolve(event);
      });
      BackWardsService.modalEvent.next({
        clickEvent,
        modalOptions: this.modalOptions,
      });
    });
  }

  loadingChange(pShow: boolean): void {
    BackWardsService.loadingEvent.next(pShow);
  }

  toastChange(params: ToastOptions) {
    BackWardsService.toastEvent.next(params);
  }
}
