export const ERROR_STATUS = {
  SANDBOX: [400, 0],
  RECAPTCHA: [406, 409],
  DEFAULT: [500],
};

export interface ErrorType {
  code: string;
  route?: string;
  title: string;
  description: string;
  labelBtn: string;
  typeScreen: string;
}

export const ERROR_TYPES: ErrorType[] = [
  {
    code: '0-000SHL',
    route: 'default',
    title: 'Lo sentimos, hay un error en el sistema',
    description: 'Inténtalo en unos minutos. (0-000SHL)',
    labelBtn: 'Entendido',
    typeScreen: 'errorPage',
  },
  {
    code: '0-001SHL',
    route: 'sandbox',
    title: 'Lo sentimos, tenemos problemas técnicos',
    description: 'Inténtalo en unos minutos. (0-001SHL)',
    labelBtn: 'Entendido',
    typeScreen: 'errorPage',
  },
  {
    code: '1-000SHL',
    route: 'recaptcha',
    title: 'Lo sentimos, no fue posible validar tu identidad',
    description: 'Inténtalo en unos minutos. (1-000SHL)',
    labelBtn: 'Entendido',
    typeScreen: 'errorPage',
  },
  {
    code: '1-001SHL',
    title: '¡Ups! Tus datos son incorrectos',
    description: 'Revísalos e inténtalo de nuevo. (1-001SHL)',
    labelBtn: 'Reintentar',
    typeScreen: 'modal',
  },
  {
    code: '1-002SHL',
    route: 'expiredPassword',
    title: 'Lo sentimos, la contraseña ha expirado',
    description:
      'Para obtener una nueva contraseña temporal debes comunicarte con el equipo de soporte y ellos te guiarán con el proceso.',
    labelBtn: 'Entendido',
    typeScreen: 'errorPage',
  },
  {
    code: '1-003SHL',
    route: 'mfaCodeNotValid',
    title: '¡Ups! El código que digitaste no es valido. (1-003SHL)',
    description: 'Revísalo e inténtalo de nuevo',
    labelBtn: 'Reintentar',
    typeScreen: 'modal',
  },
];
