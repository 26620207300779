import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../../../login/services/login/login.service';
import { LogoutService } from '../../../login/services/logout/logout.service';
import { IconSelect, IModalButton } from '../../../utils-ui/models/modalOptions';
import { BackWardsService } from '../../../utils-ui/services/backwards/backwards.service';
import { StorageService } from '../../../utils-ui/services/storage/storage.service';
import { ComunicationComponentsService } from '../../../dashboard/services/comunication-componets/comunication-components.service';
import { ambience } from '../../../../assets/data/constants';

@Component({
  selector: 'app-ae-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() closeEnable = '';
  titleText = 'Links - Soluciones Digitales';
  headerTitle: string;

  constructor(
    private readonly router: Router,
    private readonly logoutService: LogoutService,
    private readonly storageService: StorageService,
    private readonly backWardsService: BackWardsService,
    private readonly loginService: LoginService,
    private readonly comunicationComponentService: ComunicationComponentsService
  ) {}

  ngOnInit(): void {
    this.validateScreen();
    this.router.events.subscribe(() => {
      if (this.router.url.includes('/clients?')) {
        this.titleText = 'Nuevo Link';
      } else {
        this.titleText = 'Links - Soluciones Digitales';
      }
    });
  }

  validateScreen(): void {
    if (screen.width > 1023 && !this.router.url.includes('/ally/mfaCode')) {
      this.headerTitle = 'true';
    } else {
      this.headerTitle = 'false';
    }
  }

  async navigateTo(): Promise<void> {
    this.comunicationComponentService.notifyHeaderEvent(this.router.url.toString());
    const currentRoute = this.router.url.toString();
    if (currentRoute.includes('/login')) {
      window.history.back();
    } else {
      await this.router.navigate(['/dashboard']);
    }
  }

  async validateSingOut(): Promise<void> {
    this.backWardsService.loadingChange(true);
    if (await this.loginService.validateSession()) {
      this.backWardsService.loadingChange(false);
      this.startSignOut();
    } else {
      this.backWardsService.loadingChange(false);
      this.signOut();
    }
  }

  startSignOut(): void {
    if (this.logoutService.thereIsAnyTransaction()) {
      this.backWardsService
        .openModal({
          icon: IconSelect.WARNING,
          title: 'Envío pendiente',
          text: 'Hay datos de clientes no enviados ¿estás seguro de cerrar sesión?',
          buttons: [
            {
              id: 'primaryButton',
              value: 'Cerrar',
            },
            {
              id: 'secondaryButton',
              value: 'Permanecer',
            },
          ],
        })
        .then((event: IModalButton) => {
          if (event.id === 'primaryButton') {
            this.logoutService.setTrasactionsToNone();
            this.signOut();
          }
        });
    } else {
      this.signOut();
    }
  }

  signOut(): void {
    this.backWardsService.loadingChange(true);
    this.logoutService.logout().finally(() => {
      this.goNavigateLogin();
      this.backWardsService.loadingChange(false);
      this.storageService.cleanStorage();
    });
  }

  async goNavigateLogin(): Promise<void> {
    this.storageService.saveUser({});
    await this.router.navigate([ambience[environment.IS_FRONT_ALLY]]);
  }
}
