import { Injectable } from '@angular/core';
import { RequestService } from '../../../utils-ui/services/request/request.service';
import { StorageService } from '../../../utils-ui/services/storage/storage.service';
import { Endpoints } from '../../../utils-ui/models/endpoints';
import { IUserStorage } from '../../../utils-ui/models/userStorage';
import { LoginRequest, LoginResponse } from '../../../utils-ui/models/loginRequest';
import { ProductService } from '../../../dashboard/services/products/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { IAllyInfo } from '../../../dashboard/model/AllyInfo';
import { CHANNEL_TYPES } from '../../../utils-ui/models/channelTypes';
import { BackWardsService } from '../../../utils-ui/services/backwards/backwards.service';
import { ErrorService } from '../../../utils-ui/services/errors/error.service';
import { firstValueFrom } from 'rxjs';
import { AlliesService } from '../../../utils-ui/services/allies/allies.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public static prefixList = [
    { text: 'BANCODEBOGOTA', value: 'bdb' },
    { text: 'BANBOGOTA.EXT', value: 'call' },
    { text: 'BOGOTA', value: 'bo.bdb' },
  ];

  allyCode = '';
  allyName = '';
  allyPointSale = '';
  allyPointSaleName = '';
  allyProduct = '';
  allyRole = '';
  token = '';
  phoneNumber = '';
  advisorChiefIdentityType: string | undefined;
  advisorChiefIdentityNumber: string | undefined;
  channelAlly = CHANNEL_TYPES[0];
  userStorage: IUserStorage;
  mfaPool: any = {};
  userInfo: any = {};

  constructor(
    private readonly requestService: RequestService,
    private readonly storageService: StorageService,
    private readonly productService: ProductService,
    private readonly router: Router,
    private readonly reCaptchaV3Service: ReCaptchaV3Service,
    private readonly backWardService: BackWardsService,
    private readonly errorService: ErrorService,
    private readonly alliesService: AlliesService
  ) {}

  async login(request: LoginRequest): Promise<LoginResponse> {
    const userStorage = this.storageService.getUser() as IUserStorage;
    const body = {
      username: request.username,
      password: request.password,
      channel: request.channel,
    };
    if (request.officeCode) {
      body['officeCode'] = request.officeCode;
    }
    if (request.sellerCode) {
      body['sellerCode'] = request.sellerCode;
    }
    if (request.advisorChiefIdentityType && request.advisorChiefIdentityNumber) {
      body['advisorChiefIdentityType'] = request.advisorChiefIdentityType;
      body['advisorChiefIdentityNumber'] = request.advisorChiefIdentityNumber;
    }
    this.storageService.saveUser({
      officeCode: request.officeCode,
      sellerCode: request.sellerCode,
    });
    return this.requestService.post(Endpoints.LOGIN, {
      headers: {
        'x-IpAddr': userStorage.ip,
        'x-ldapPrefix': request.ldapPrefix,
      },
      body,
      avoidToken: true,
    });
  }

  async createLinkRequest(
    pAdvisorUsername: string,
    officeCode?: string,
    sellerCode?: string,
    allyCode?: string,
    advisorChiefIdentityType?: string,
    advisorChiefIdentityNumber?: string
  ): Promise<void> {
    const headers = {
      'x-advisor-username': pAdvisorUsername,
      'office-code': officeCode,
      'seller-code': sellerCode,
      'ally-code': allyCode,
      'chief-identity-type': advisorChiefIdentityType,
      'chief-identity-number': advisorChiefIdentityNumber,
    };
    this.productService.cleanProducts();
    return this.requestService.put(Endpoints.CREATE_REQUEST, {
      headers,
      responseType: 'text',
    });
  }

  async createLinkRequestExternal(
    pAdvisorUsername: string,
    allyCode: string,
    allyPointSale: string,
    advisorChiefIdentityType?: string,
    advisorChiefIdentityNumber?: string
  ): Promise<any> {
    this.productService.cleanProducts();
    return this.requestService.put(Endpoints.CREATE_REQUEST, {
      headers: {
        'x-advisor-username': pAdvisorUsername,
        'ally-code': allyCode,
        'ally-point-sale': allyPointSale,
        'chief-identity-type': advisorChiefIdentityType,
        'chief-identity-number': advisorChiefIdentityNumber,
      },
      responseType: 'text',
    });
  }

  async validateSession(): Promise<boolean> {
    const userStorage = this.storageService.getUser() as IUserStorage;
    return new Promise((resolve) => {
      if (userStorage?.rqUuid) {
        this.requestService
          .get(Endpoints.VALIDATE_SESSION, {
            headers: {
              'X-RqUID': userStorage.rqUuid,
            },
          })
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  }

  saveUserToLocalStorage(ally: any, data: any, allyInfo: IAllyInfo): void {
    this.channelAlly = CHANNEL_TYPES.find((channelSelected) => channelSelected.id === ally.channel);
    this.userStorage = {
      channel: this.channelAlly.homologate_value,
      channelHomologateCode: this.channelAlly.homologate_code,
      channelId: String(this.channelAlly.id),
      name: '',
      username: ally.username,
      advisorChiefIdentityNumber: allyInfo.advisorChiefIdentityNumber,
      advisorChiefIdentityType: allyInfo.advisorChiefIdentityType,
      allyRole: allyInfo.allyRole,
      allyCode: allyInfo.allyCode,
      allyName: allyInfo.allyName,
      allyPointSale: allyInfo.allyPointSale,
      allyPointSaleName: allyInfo.pointSaleName,
      allyProduct: allyInfo.allyProduct,
      token: allyInfo.token,
      rqUuid: data.uuid,
      identificationNumber: ally.identificationNumber,
      identificationType: ally.identificationType,
      phoneNumber: allyInfo.phoneNumber,
    };
    this.storageService.saveUser(this.userStorage);
  }

  async loginExternal($event: any): Promise<void> {
    const ally = $event.detail.resultTokenService.advisor;
    const data = $event.detail.resultTokenService.data;
    let phoneAlly;
    if ($event.detail.userData) {
      phoneAlly = $event.detail.userData.phone_number.slice(3);
      this.allyRole = $event.detail.userData['custom:user_role'];
      this.allyCode = $event.detail.userData['custom:ally_code'];
      this.allyName = $event.detail.userData['custom:ally_name'];
      this.allyPointSale = $event.detail.userData['custom:branch_code'];
      this.allyProduct = $event.detail.userData['custom:product_name'];
      this.token = $event.detail.userData['custom:Token'];
      this.allyPointSaleName = $event.detail.userData['custom:branch_name'];
    }

    await this.alliesService
      .getChiefAdvisor(this.token ?? this.allyCode, this.allyPointSale)
      .then((response) => {
        const [chief] = response;
        this.advisorChiefIdentityType = chief.identificationType;
        this.advisorChiefIdentityNumber = chief.identificationNumber;
      })
      .catch(() => {});

    this.saveUserToLocalStorage(ally, data, {
      allyRole: this.allyRole,
      allyCode: this.allyCode,
      allyName: this.allyName,
      allyPointSale: this.allyPointSale,
      pointSaleName: this.allyPointSaleName,
      allyProduct: this.allyProduct,
      token: this.token,
      phoneNumber: this.phoneNumber || phoneAlly,
      advisorChiefIdentityType: this.advisorChiefIdentityType,
      advisorChiefIdentityNumber: this.advisorChiefIdentityNumber,
    });

    await this.createLinkRequestExternal(
      ally.username,
      this.allyCode,
      this.allyPointSale,
      this.advisorChiefIdentityType,
      this.advisorChiefIdentityNumber
    );
  }

  async userPoolMfa(event: any, relativeTo: ActivatedRoute): Promise<void> {
    this.mfaPool = event.detail;
    await this.router.navigate(['mfaCode'], { relativeTo });
  }

  private getRecaptchaV3Token(action: string): Promise<string> {
    return firstValueFrom(this.reCaptchaV3Service.execute(action));
  }

  async runAndValidateRecaptchaV3(action: string): Promise<any> {
    const recaptchaToken = await this.getRecaptchaV3Token(action);
    return this.validateRecaptchaByVersion(recaptchaToken, 'V3');
  }

  async validateRecaptchaV2(recaptchaToken: string): Promise<any> {
    return this.validateRecaptchaByVersion(recaptchaToken, 'V2');
  }

  private async validateRecaptchaByVersion(
    recaptchaToken: string,
    recaptchaVersion: 'V2' | 'V3'
  ): Promise<any> {
    const res = await this.requestService.post(Endpoints.RECAPTCHA, {
      body: { recaptchaToken, recaptchaVersion },
      avoidToken: true,
    });

    if (res.status !== 'OK') {
      throw new Error('NOT OK');
    }

    return res;
  }

  async userData(userData: any): Promise<void> {
    this.allyCode = userData['custom:ally_code'];
    this.allyPointSale = userData['custom:branch_code'];
    this.allyName = userData['custom:ally_name'];
    this.allyRole = userData['custom:user_role'];
    this.allyPointSaleName = userData['custom:branch_name'];
    this.allyProduct = userData['custom:product_name'];
    this.token = userData['custom:Token'];
    this.phoneNumber = userData['phone_number'].slice(3);
  }

  async signinEvent($event: any): Promise<void> {
    this.backWardService.loadingChange(true);

    try {
      await this.loginExternal($event);
      await this.router.navigate(['dashboard']);
      this.backWardService.loadingChange(false);
    } catch (error) {
      this.backWardService.loadingChange(false);
      this.errorService.openScreenError(this.errorService.filterErrorbyHttp(error.status));
    }
  }
}
