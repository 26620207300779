import { NgModule } from '@angular/core';
import { InvisibleRecaptchaComponent } from './invisible-recaptcha/invisible-recaptcha.component';
import {
  RecaptchaV3Module,
  RecaptchaModule,
  RecaptchaLoaderService,
  RECAPTCHA_V3_SITE_KEY,
  RECAPTCHA_SETTINGS,
} from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { PreloadedRecaptchaService } from './preloaded-recaptcha.service';

@NgModule({
  declarations: [InvisibleRecaptchaComponent],
  imports: [RecaptchaV3Module, RecaptchaModule],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_WK,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.RECAPTCHA_SITE_KEY_V2,
        type: 'image',
        size: 'invisible',
        theme: 'light',
        badge: 'bottomright',
      },
    },
    {
      provide: RecaptchaLoaderService,
      useValue: new PreloadedRecaptchaService(),
    },
  ],
  exports: [InvisibleRecaptchaComponent],
})
export class RecaptchaCustomModule {}
