import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements as bdbDtCustomElements } from '@npm-bbta/bbog-dig-dt-webcomponents-lib/loader';
import { defineCustomElements as bdbDtCustomElementsWidgets } from '@npm-bbta/bbog-ae-private-web-components-lib/loader';

bdbDtCustomElements(window);
bdbDtCustomElementsWidgets(window);
if (environment.production) {
  enableProdMode();
}

const script = document.createElement('script');
script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${environment.tagManagerId}');`;
document.head.appendChild(script);

const scriptHotjar = document.createElement('script');
scriptHotjar.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).
  push(arguments)}; h._hjSettings={hjid:${environment.ID_HOTJAR},hjsv:6}; a=o.getElementsByTagName('head')[0]; 
  r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

if (environment.TOGGLE_SCRIPT_HOTJAR) {
  document.head.appendChild(scriptHotjar);
}

const noscript = document.createElement('noscript');
noscript.innerHTML = `<iframe
      src="https://www.googletagmanager.com/ns.html?id=${environment.tagManagerId}"
      height="0" width="0" style="display:none;visibility:hidden">
  </iframe>`;
document.body.insertBefore(noscript, document.body.firstChild);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
