import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';
import { Endpoints } from '../../models/endpoints';

@Injectable({
  providedIn: 'root',
})
export class AlliesService {
  constructor(private readonly requestService: RequestService) {}

  async getAllieds(allied: string): Promise<any> {
    return this.requestService.get(
      Endpoints.ALLIED,
      {
        avoidToken: true,
        queryParams: {},
        headers: {
          'allied-text': allied,
        },
        responseType: 'text',
      },
      5,
      2500
    );
  }

  async getChiefAdvisor(alliedId: string, salePointCode: string) {
    const uri = `${Endpoints.ALLIED}/${alliedId}/salePoints/${salePointCode}/advisors/chief`;
    return this.requestService.get(
      uri,
      {
        avoidToken: true,
        queryParams: {},
        responseType: 'text',
      },
      5,
      2500
    );
  }
}
