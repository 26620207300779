export interface ChannelType {
  id: number;
  homologate_code: string;
  description: string;
  homologate_value: string;
}

export const CHANNEL_TYPES: ChannelType[] = [
  {
    id: 1,
    homologate_code: 'Oficina',
    description: 'Oficina',
    homologate_value: 'Oficina',
  },
  {
    id: 7,
    homologate_code: 'Aliado',
    description: 'Aliados',
    homologate_value: 'Aliado',
  },
  {
    id: 13,
    homologate_code: 'CR',
    description: 'Corresponsales',
    homologate_value: 'Corresponsales',
  },
];

export const CHANNEL_BY_PRODUCT = {
  'Libre Destino': {
    channel: '13',
  },
  'CeroPay': {
    channel: '7',
  },
};
