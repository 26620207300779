import { Injectable } from '@angular/core';
import { Customer } from '../../models/customer';
import { CryptoService } from '../crypto/crypto.service';

@Injectable()
export class StorageService {
  device: any;
  customerStorage: Customer[] = [];

  constructor(public cryptoService: CryptoService) {}

  getFromSession(key: string): any {
    const ct = sessionStorage.getItem(btoa(key));
    if (ct === null) {
      return null;
    }
    return this.cryptoService.decrypt(ct);
  }

  saveToSession(key: string, value: any): void {
    if (!value) {
      sessionStorage.removeItem(btoa(key));
    } else {
      sessionStorage.setItem(btoa(key), this.cryptoService.encrypt(value));
    }
  }

  getFromLocal(key: string): any {
    const ct = localStorage.getItem(btoa(key));
    if (ct === null) {
      return null;
    }
    return this.cryptoService.decrypt(ct);
  }

  deleteFromLocal(key: string): any {
    localStorage.removeItem(btoa(key));
  }

  saveToLocal(key: string, value: any): void {
    if (!value) {
      localStorage.removeItem(btoa(key));
    } else {
      localStorage.setItem(btoa(key), this.cryptoService.encrypt(value));
    }
  }

  getInitModal(): string {
    const im = localStorage.getItem('im');
    if (im === null) {
      return null;
    }
    return im;
  }

  setInitModal(): void {
    localStorage.setItem('im', btoa('true'));
  }

  getUser(): any {
    return JSON.parse(this.getFromSession('c')) || null;
  }

  saveUser(content): void {
    if (!content) {
      this.saveToSession('c', null);
    } else {
      let userStorage = this.getUser() || {};
      userStorage = Object.assign(userStorage, content);
      this.saveToSession('c', JSON.stringify(userStorage));
    }
  }

  getNav(): any {
    return this.getFromSession('nav') ? JSON.parse(this.getFromSession('nav')) : null;
  }

  saveNav(content): void {
    if (!content) {
      this.saveToSession('nav', null);
    } else {
      let navStorage = this.getNav() || {};
      navStorage = Object.assign(navStorage, content);
      this.saveToSession('nav', JSON.stringify(navStorage));
    }
  }

  cleanStorage(): void {
    sessionStorage.clear();
    localStorage.clear();
  }
}
