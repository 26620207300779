import { Injectable } from '@angular/core';
import { NaclSbxUtil } from '@npm-bbta/bbog-ate-sbx-utilities-lib';
import * as CryptoJS from 'crypto-js';
import { Md5 } from 'ts-md5';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CryptoService {
  private readonly whiteToken = 'whiteToken';

  setEncryptionRequest(body, pAuthToken: string, options, headers): any {
    const parameters: any = environment.SBX_PARAMETERS_v2;
    parameters.Seed = {
      nonce: NaclSbxUtil.generateCustomNonce(pAuthToken || this.whiteToken),
    };

    const encryptedBody = NaclSbxUtil.generateJWTPublicKey(
      body,
      environment.SBX_RQ_PBK,
      parameters
    );

    if (headers) {
      const headersResp: any[] = [];
      const headersDef: string[] = Object.keys(headers);
      headersDef.forEach((name) => {
        if (name && headers[name] && !options.get[name]) {
          headersResp.push({ [name]: headers[name] });
        }
      });
      const encryptedHeaders = NaclSbxUtil.generateJWTPublicKey(
        JSON.stringify(headersResp),
        environment.SBX_RQ_PBK,
        parameters
      );
      options = options.set('x-custom-headers', encryptedHeaders);
    }

    options = options.set('x-auth-token', pAuthToken || this.whiteToken);
    options = options.set('x-strcode', Md5.hashStr(encryptedBody as string));

    return { encryptedBody, options };
  }

  getEncryptionResponse(value: string, authToken: string): any {
    try {
      if (value && authToken) {
        const parameters: any = environment.SBX_PARAMETERS_v2;
        parameters.Seed = {
          nonce: NaclSbxUtil.generateCustomNonce(authToken),
        };

        return NaclSbxUtil.validateJWT(value, environment.SBX_RS_PBK, parameters);
      }
      return null;
    } catch (error) {
      throw new Error('Exception uncipherValue');
    }
  }

  encrypt(data: any): string {
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), environment.CYPHER_JS_KEY);
    return cipherText.toString();
  }

  decrypt(cipherText: string): any {
    const bytes = CryptoJS.AES.decrypt(cipherText, environment.CYPHER_JS_KEY);
    return this.parseItem(bytes.toString(CryptoJS.enc.Utf8));
  }

  parseItem(item: any): any {
    if (item === null || item === undefined) {
      return null;
    }
    return JSON.parse(item);
  }
}
