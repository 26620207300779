import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComunicationComponentsService {
  eventHeader = new EventEmitter();
  eventSendButton = new EventEmitter();

  notifyHeaderEvent(parameter: any): void {
    this.eventHeader.emit(parameter);
  }

  notifySendLinkEvent(parameter: any): void {
    this.eventSendButton.emit(parameter);
  }
}
