import { Injectable } from '@angular/core';
import { RequestService } from '../../../utils-ui/services/request/request.service';
import { Product, ProductFromService } from '../../../utils-ui/models/product';
import { CryptoService } from '../../../utils-ui/services/crypto/crypto.service';
import { Endpoints } from '../../../utils-ui/models/endpoints';
import { StorageService } from '../../../utils-ui/services/storage/storage.service';
import { IUserStorage } from '../../../utils-ui/models/userStorage';
import { LinkSessionService } from '../../../utils-ui/services/session/linkSession.service';
import { ErrorService } from '../../../utils-ui/services/errors/error.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  static products: Product[] = [];

  constructor(
    private readonly requestService: RequestService,
    private readonly cryptoService: CryptoService,
    private readonly storageService: StorageService,
    private readonly linkSessionService: LinkSessionService,
    private readonly errorService: ErrorService
  ) {}

  public static readonly productPictogramsMap = {
    CDT: 'CDT',
    LD: 'LIBRE_DESTINO',
    TC: 'TARJETA_CREDITO',
    SD: 'SEGUROS',
    CC: 'CREDICONVENIOS',
    TCPC: 'FIRST_PURCHASE',
    CD: 'AHORRO',
    LBZ: 'LIBRANZA',
    MM: 'MICROFINANZAS',
    VHC: 'VEHICULO',
    CN: 'AHORRO',
    CPM: 'CUENTA_AHORRO',
  } as const;

  static readonly productNameLogoMap = { ceropay: 'CEROPAY' } as const;

  public transformData(product: ProductFromService): Product {
    return {
      idURL: this.cryptoService.encrypt(product.id),
      productURL: this.cryptoService.encrypt(product.name),
      channelURL: 'BancaVirtual',
      imageSrc: ProductService.productPictogramsMap[product.prefix],
      name: product.name,
      enabler: product.enable === 1,
      productXname: product.product_xname,
    };
  }

  async getProducts(): Promise<Product[]> {
    if (ProductService.products.length > 0) {
      return ProductService.products;
    }

    try {
      const productsFromBack = await this.getProductsFromBack();
      const userInfo = this.storageService.getUser() as IUserStorage;
      let filteredProducts: any[];
      if (userInfo.channel === 'Aliado') {
        filteredProducts = this.filterProductsByAlly(productsFromBack);
      } else {
        filteredProducts = this.filterProductByChannel(productsFromBack);
      }
      this.linkSessionService.setProductsSession(filteredProducts);
      ProductService.products = filteredProducts
        .map((product) => this.transformData(product))
        .sort((a: Product, b: Product) => a.name.localeCompare(b.name));
      return ProductService.products;
    } catch (error) {
      if (error.status === 0 || error.status >= 500) {
        this.errorService.openScreenError('0-000SHL');
      }

      return [];
    }
  }

  cleanProducts(): void {
    ProductService.products = [];
  }

  public getProductsFromBack(): Promise<ProductFromService[]> {
    const user = this.storageService.getUser() as IUserStorage;
    return this.requestService.get(Endpoints.PRODUCTS, {
      headers: {
        'X-RqUID': user.rqUuid,
        'office-code': user.officeCode,
        'seller-code': user.sellerCode,
        'chief-identity-type': user.advisorChiefIdentityType,
        'chief-identity-number': user.advisorChiefIdentityNumber,
      },
      responseType: 'text',
    });
  }

  public filterProductsByAlly(products: ProductFromService[]): ProductFromService[] {
    const userInfo = this.storageService.getUser() as IUserStorage;
    if (userInfo.allyName) {
      const allyProducts = userInfo.allyProduct.split(',').map((product) => product.trim());
      return products.filter((product) => allyProducts.includes(product.name));
    }
    return products;
  }

  public filterProductByChannel(products: ProductFromService[]): ProductFromService[] {
    const userInfo = this.storageService.getUser() as IUserStorage;
    const userChannelId = userInfo.channelId.toString();
    return products.filter((product) => {
      const productChannelIds = product.channel_ids.split(',').map((id) => id.trim());
      return productChannelIds.includes(userChannelId);
    });
  }
}
