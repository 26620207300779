import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { CanActivateAuthGuard } from './utils-ui/services/session/canActivateAuthGuard.service';
import { ambience } from '../assets/data/constants';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [CanActivateAuthGuard],
  },
  {
    path: ambience[environment.IS_FRONT_ALLY],
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'error/:route',
    loadChildren: () =>
      import('./utils-ui/components/error/error-routing.module').then((m) => m.ErrorRoutingModule),
  },
  {
    path: 'emptyState',
    loadChildren: () =>
      import('./utils-ui/components/empty-state/empty-state-routing.module').then(
        (m) => m.EmptyStateRoutingModule
      ),
  },
  {
    path: '',
    redirectTo: ambience[environment.IS_FRONT_ALLY],
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
