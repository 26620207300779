import { Auth } from 'aws-amplify';
import awsExports from './awsExports.json';
import { environment } from '../../../../environments/environment';

export class CognitoService {
  constructor() {
    awsExports.aws_user_pools_id = environment.awsUserPoolsId;
    awsExports.aws_user_pools_web_client_id = environment.awsUserPoolsWebClientId;

    Auth.configure(awsExports);
  }

  async signOut(): Promise<any> {
    try {
      return await Auth.signOut({ global: true });
    } catch (error) {
      console.error('error signing out: ', error);
      throw new Error(error);
    }
  }
}
