import { Injectable } from '@angular/core';
import { Endpoints } from '../../models/endpoints';
import { RequestService } from '../request/request.service';

@Injectable()
export class SellerService {
  constructor(private readonly requestService: RequestService) {}

  public getSellers(): Promise<any> {
    return this.requestService.get(Endpoints.SELLER, {
      avoidToken: true,
      queryParams: {},
      responseType: 'text',
    });
  }
}
