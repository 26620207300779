import { Injectable } from '@angular/core';
import { RequestService } from '../../../utils-ui/services/request/request.service';
import { StorageService } from '../../../utils-ui/services/storage/storage.service';
import { Endpoints } from '../../../utils-ui/models/endpoints';
import { IUserStorage } from '../../../utils-ui/models/userStorage';
import { ListClientsComponent } from '../../../dashboard/components/list-clients/list-clients.component';
import { environment } from '../../../../environments/environment';
import { CognitoService } from '../../../utils-ui/services/cognito/cognito.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private readonly requestService: RequestService,
    private readonly storageService: StorageService,
    private readonly cognitoService: CognitoService
  ) {}

  setTrasactionsToNone(): void {
    ListClientsComponent.isArrayEmpty = true;
  }

  thereIsAnyTransaction(): boolean {
    return !ListClientsComponent.isArrayEmpty;
  }

  async logout(): Promise<any> {
    const userStorage = this.storageService.getUser() as IUserStorage;
    if (environment.IS_FRONT_ALLY === 2) {
      return this.cognitoService.signOut();
    } else {
      return this.requestService.delete(Endpoints.LOGOUT, {
        headers: {
          'X-RqUID': userStorage.rqUuid,
        },
        queryParams: {},
      });
    }
  }
}
