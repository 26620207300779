import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../../../login/services/login/login.service';
import { BackWardsService } from '../backwards/backwards.service';
import { ambience } from '../../../../assets/data/constants';

@Injectable()
export class CanActivateAuthGuard implements CanActivate {
  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private readonly backWardService: BackWardsService
  ) {}

  async canActivate(): Promise<boolean> {
    if (environment.production === 'dev') {
      return true;
    }

    this.backWardService.loadingChange(true);

    if (await this.loginService.validateSession()) {
      this.backWardService.loadingChange(false);
      return true;
    }

    await this.router.navigate([ambience[environment.IS_FRONT_ALLY]]);

    this.backWardService.loadingChange(false);
    return false;
  }
}
