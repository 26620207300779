export interface IModalOptions {
  icon: IconSelect;
  title: string;
  text: string;
  buttons: IModalButton[];
}

export interface IModalButton {
  id: string;
  value: string;
}

export enum IconSelect {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFORMATION = 'INFORMATION',
}
