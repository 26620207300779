import { Injectable } from '@angular/core';
import { Breakpoints, TypeDevice } from './DeviseUtils';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private readonly tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
  private readonly phoneRegex =
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

  getTypeDevice(): TypeDevice {
    const ua = navigator.userAgent;
    if (this.tabletRegex.test(ua)) {
      return TypeDevice.TABLET;
    }

    if (this.phoneRegex.test(ua)) {
      return TypeDevice.SMARTPHONE;
    }

    return TypeDevice.DESKTOP;
  }

  getDeviseByWidth(): TypeDevice {
    if (screen.width <= Breakpoints.MAX_WIDTH_CELLPHONE) {
      return TypeDevice.SMARTPHONE;
    }
    return screen.width <= Breakpoints.MAX_WIDTH_TABLET ? TypeDevice.TABLET : TypeDevice.DESKTOP;
  }
}
