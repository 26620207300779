import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ICheckAttributes } from '@npm-bbta/bbog-dig-dt-webcomponents-lib/dist/types/components/sherpa-at/bdb-at-check-button/ICheck';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent {
  @Input() title = '';
  @Input() placeHolder = 'Todos';
  @Input() options: string[];
  @Input() selectAllOption: string;
  @Output() selectedOptionsChange = new EventEmitter<any>();
  @ViewChild('containerMenu', { static: false }) dropdownMenu: ElementRef;
  public optionsStructure: ICheckAttributes[] = [];
  public optionsToRender: string;
  public selectAllOptRender: ICheckAttributes[];
  public checkedProducts: string[];
  public disableApplyBtn = false;
  public show = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.createOptions(this.options, this.selectAllOption);
    }
  }
  openDrop(event: Event): void {
    event.stopPropagation();
    if (this.show) {
      this.emitOptionsAndClose();
    } else {
      this.show = true;
    }
  }
  createOptions(options: string[], selectAllOption: string): void {
    this.selectAllOptRender = [
      {
        label: selectAllOption,
        value: 'lblnode',
        isChecked: 'true',
      },
    ];

    this.optionsStructure = options.map((option) => ({
      label: option,
      value: option,
      isChecked: 'true',
    }));
    this.optionsToRender = JSON.stringify(this.optionsStructure);
  }
  checkEmitterRender(event) {
    this.checkedProducts = [];
    if (event.detail[0].value === 'lblnode' && event.detail[0].isChecked === 'true') {
      this.checkedProducts = this.options;
      this.disableApplyBtn = false;
      this.placeHolder = 'Todos';
    } else if (
      event.detail.length === 1 &&
      event.detail[0].value === 'lblnode' &&
      event.detail[0].isChecked === 'false'
    ) {
      this.disableApplyBtn = true;
      this.placeHolder = 'Selecciona el producto';
    } else if (event.detail.length > 1) {
      event.detail.forEach((product) => {
        if (product.isChecked === 'true') {
          this.checkedProducts.push(product.value);
          this.disableApplyBtn = false;
        }
      });
      if (this.checkedProducts.length === 1) {
        this.placeHolder = this.checkedProducts[0];
      } else if (
        this.checkedProducts.length > 1 &&
        this.checkedProducts.length < this.options.length
      ) {
        this.placeHolder = 'Selección personalizada';
      }
    }
  }

  emitOptionsAndClose(): void {
    this.selectedOptionsChange.emit(this.checkedProducts);
    this.show = false;
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event): void {
    if (this.show && !this.dropdownMenu.nativeElement.contains(event.target)) {
      this.emitOptionsAndClose();
    }
  }
}
